import axios from 'axios'
import baseURL from './baseUrl'

// 生成请求实例
let server = axios.create({
  baseURL,
  timeout: 1000 * 50,
})
// 请求之前验证
server.interceptors.request.use(config => {
  const token = window.sessionStorage.getItem('token')
  if (token) {
    config.headers.common.Authorization = token
  }
  if (config.method === 'get' && config.url.includes('isFileImg')) {
    config.responseType = 'blob'
  }
  config.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
  return config
}, error => {
  return Promise.reject(error)
})

const err = '请求出错了'
// 处理返回的数据
server.interceptors.response.use(response => {
  const { status, data } = response
  if (data instanceof Blob) {
    if (data.resultCode) {
      return Promise.reject(data.resultMsg)
    } else {
      return data
    }
  }
  if (status === 200) {
    if (data.resultCode === '0000') {
      return data.obj || {}
    } else if (data.resultCode === '1002') {
      return Promise.reject(data)
    } else {
      return Promise.reject(data.resultMsg)
    }
  } else {
    return Promise.reject(err)
  }
}, err => {
  if (err.message.includes('timeout')) {
    return Promise.reject('请求超时')
  }
  return Promise.reject(err)
})

export default server