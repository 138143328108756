import server from './service'
export const encryptData = params => server.post('/encryptData', params)

export const cashierOrderQuery = params => server.post('/cashierOrderQuery', params) //订单明细查询

export const payStatusQuery = params => server.post('/payStatusQuery', params) //根据订单号查询支付状态

export const getMcode = params => server.post('/mcode/getMcode', params) //

export const initiatePay = params => server.post('/initiatePay', params) //用户选择完支付渠道，点击去支付

export const scanToPay = params => server.get(`/qrPay/scanToPay?paymentNo=${params.paymentNo}&isFileImg=1`, params) //url转二维码

export const sendCheckCode = params => server.post(`/wallet/sendCheckCode`, params) //url转二维码

export const calculateCommission = params => server.post(`/commission/calculateCommission`, params) //佣金查询
